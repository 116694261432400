<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Criar from "@/components/widgets/pedidos-criar";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Criar,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      loading: true,
      errored: false,
      total: 0,
      fields: [
        { key: "id", label: "#" },
        { key: "date", label: "Data" },
        { key: "client_name", label: "Nome do Cliente" },
        { key: "items", label: "Items" },
        { key: "total", label: "Total" },
        { key: "status", label: "Status" },
        { key: "details", label: "", }
      ],
      items: null,

      status: 'all',
      sortBy: "id",
      sortDesc: true,
      currentPage: "1",
      perPage: "50",
      filter: "",

      criar: {
        modal: false,
      },
    };
  },
  methods: {
    getPedidos() {
      this.loading = true;

      api.get("pedidos?status=" + this.status).then((res) => {
        if (res.data.status == "success") {
          this.total = res.data.total;
          this.items = res.data.list;
        }
      })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clickOrder(item) {
      if (item.id) {
        this.$router.push("/pedidos/" + item.id);
      }
    },
    showCriar() {
      this.criar.modal = false;
      this.criar.modal = true;
    }
  },
  mounted() {
    this.getPedidos();
  },
  watch: {
    status: function () {
      this.getPedidos();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Pedidos</h3>
        <p></p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <div class="totals">
        <h6 class="m-0" v-if="items">{{ items.length }}</h6>
        <h6 class="m-0" v-else>0</h6>
        <h6 class="m-0 text-secondary">Total de pedidos</h6>
      </div>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Filtrar status
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="status" value="all">Todos</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="open">Abertos</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="pending">Pendentes</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="paid">Pagos</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="in_processing">Em separação</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="shipped">Enviados</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="canceled">Cancelados</b-form-radio></b-dropdown-form>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Itens por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item v-on:click="showCriar();">Criar pedido</b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="card">
      <div class="card-body d-flex align-items-center justify-content-center">
        <div v-if="errored" class="text-center">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <div v-else-if="loading" class="text-center">
          <b-spinner variant="default" role="status"></b-spinner>
        </div>
        <div v-else-if="items && items.length == 0" class="text-center">
          Nenhum pedido encontrado.
        </div>
        <div v-else class="table-responsive">
          <b-input v-model="filter" placeholder="Busca" class="mb-4"></b-input>
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover table-pointer" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" :filter="filter" @row-clicked="clickOrder" responsive>
            <template #cell(items)="row">
              {{ row.item.items.length }}
            </template>
            <template #cell(total)="row">
              {{ row.item.total | currency }}
            </template>
            <template #cell(status)="row">
              <div v-if="row.item.status == 'open'">Aberto</div>
              <div v-else-if="row.item.status == 'pending'">Pendente</div>
              <div v-else-if="row.item.status == 'paid'">Pago</div>
              <div v-else-if="row.item.status == 'in_processing'">Em separação</div>
              <div v-else-if="row.item.status == 'shipped'">Enviado</div>
              <div v-else-if="row.item.status == 'canceled'">Cancelado</div>
            </template>
            <template #cell(details)="row">
              <router-link tag="a" :to="'/pedidos/' + row.item.id">
                Ver pedido
              </router-link>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" pills align="center"></b-pagination>

    <Criar :modal="criar.modal" />
  </Layout>
</template>
